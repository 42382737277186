import React, { useState } from 'react';
import { Container, Box, Typography, Button, Grid, Card, CardContent, TextField, Avatar } from '@mui/material';

const UserProfile: React.FC = () => {
    const [userInfo, setUserInfo] = useState({
        name: 'John Doe',
        email: 'john.doe@example.com',
        password: '',
        confirmPassword: '',
        avatar: '/static/images/avatar/1.jpg',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    };

    const handleSaveChanges = () => {
        // Save profile changes logic here
        console.log('Profile updated:', userInfo);
    };

    return (
        <Container maxWidth="md" className="pt-6">
            <Box className="text-center mb-6">
                <Typography variant="h4">Profile</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} className="text-center">
                    <Card>
                        <CardContent>
                            <Avatar alt={userInfo.name} src={userInfo.avatar} className="mx-auto mb-4" sx={{ width: 120, height: 120 }} />
                            <Typography variant="h6">{userInfo.name}</Typography>
                            <Typography variant="body2" color="textSecondary">{userInfo.email}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" className="mb-4">Edit Profile</Typography>
                            <Box component="form" noValidate autoComplete="off">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            value={userInfo.name}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={userInfo.email}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            name="password"
                                            type="password"
                                            value={userInfo.password}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            type="password"
                                            value={userInfo.confirmPassword}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="text-center">
                                        <Button variant="contained" color="primary" onClick={handleSaveChanges}>Save Changes</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserProfile;
