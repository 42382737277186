import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import '../styles/PublicGuides.css';
import { Country } from 'country-state-city';
import FlagIcon from '../components/FlagIcon';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const guidesData = [
    { id: 1, city: 'Da Nang', country: 'Vietnam', user: { name: 'John Doe', avatar: '/avatars/avatar1.jpg' } },
    { id: 2, city: 'Paris', country: 'France', user: { name: 'Jane Smith', avatar: '/avatars/avatar2.jpg' } },
    { id: 3, city: 'Tokyo', country: 'Japan', user: { name: 'Alex Johnson', avatar: '/avatars/avatar3.jpg' } },
    { id: 4, city: 'Rekjavik', country: 'Iceland', user: { name: 'Emma Brown', avatar: '/avatars/avatar4.jpg' } },
    { id: 5, city: 'Rome', country: 'Italy', user: { name: 'Michael Lee', avatar: '/avatars/avatar5.jpg' } },
    { id: 6, city: 'Sydney', country: 'Australia', user: { name: 'Olivia White', avatar: '/avatars/avatar6.jpg' } },
    { id: 7, city: 'Rodos', country: 'Greece', user: { name: 'Sophia Taylor', avatar: '/avatars/avatar7.jpg' } },
    { id: 8, city: 'Rio de Janeiro', country: 'Brazil', user: { name: 'Liam Wilson', avatar: '/avatars/avatar8.jpg' } },
    { id: 11, city: 'Berlin', country: 'Germany', user: { name: 'John Doe', avatar: '/avatars/avatar1.jpg' } },
    { id: 12, city: 'Paris', country: 'France', user: { name: 'Jane Smith', avatar: '/avatars/avatar2.jpg' } },
    { id: 13, city: 'Tokyo', country: 'Japan', user: { name: 'Alex Johnson', avatar: '/avatars/avatar3.jpg' } },
    { id: 14, city: 'London', country: 'United Kingdom', user: { name: 'Emma Brown', avatar: '/avatars/avatar4.jpg' } },
    { id: 15, city: 'Rome', country: 'Italy', user: { name: 'Michael Lee', avatar: '/avatars/avatar5.jpg' } },
    { id: 16, city: 'Sydney', country: 'Australia', user: { name: 'Olivia White', avatar: '/avatars/avatar6.jpg' } },
    { id: 17, city: 'Athens', country: 'Greece', user: { name: 'Sophia Taylor', avatar: '/avatars/avatar7.jpg' } },
    { id: 18, city: 'Rio de Janeiro', country: 'Brazil', user: { name: 'Liam Wilson', avatar: '/avatars/avatar8.jpg' } },
];

const PublicGuides = () => {
    const allCountries = Country.getAllCountries();
    const flags = allCountries
        .filter(({name}) => guidesData.some((guide) => guide.country === name))
        .reduce((acc: any, { name, isoCode }) => {
            acc[name] = isoCode;
            return acc;
        }, {});

    const [filteredGuides, setFilteredGuides] = useState(guidesData);
    const [page, setPage] = useState(1);
    const itemsPerPage = 9;

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = guidesData.filter((guide) =>
            guide.city.toLowerCase().includes(searchTerm) ||
            guide.country.toLowerCase().includes(searchTerm)
        );
        setFilteredGuides(filtered);
        setPage(1); // Reset pagination when filtering
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>Public Guides</Typography>
            <Typography variant="body1" align="center" gutterBottom>
                Explore city guides created by users around the world. Filter by city or country.
            </Typography>
            <Box mt={4} mb={6} display="flex" justifyContent="center" sx={{maxWidth: '350px', mx: 'auto'}}>
                <TextField
                    label="Search City or Country"
                    variant="standard"
                    onChange={handleSearchChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Grid container spacing={3}>
                {filteredGuides.slice(startIndex, endIndex).map((guide) => (
                    <Grid item xs={12} sm={6} md={4} key={guide.id}>
                        <Box
                            className="guide-box"
                            style={{
                                backgroundImage: `url(https://picsum.photos/800/600?random=${guide.id})`,
                            }}
                        >
                            {flags[guide.country] && <div className="flag-icon"><FlagIcon isoCode={flags[guide.country]} width={20} imageWidth={32} /></div>}
                            <Typography variant="h6" className="city-name">
                                {guide.city}, {guide.country}
                            </Typography>
                            <Box className="avatar-container" title={`by ${guide.user.name}`}>
                                <Avatar alt={guide.user.name} src={guide.user.avatar} className="user-avatar" />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                    count={Math.ceil(filteredGuides.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </>
    );
};

export default PublicGuides;
