import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import customMarkerIcon from '../assets/icons/marker-icon-2x.png';
import customShadowIcon from '../assets/icons/marker-shadow.png';

const customIcon = new L.Icon({
    iconUrl: customMarkerIcon,
    iconSize: [30, 52],
    iconAnchor: [22, 38],
    popupAnchor: [-3, -40],
    shadowUrl: customShadowIcon,
    shadowSize: [41, 41],
    shadowAnchor: [20, 27],
});

// @ts-ignore
const UpdateMapView = ({ position, zoom, markers }) => {
    const map = useMap();

    useEffect(() => {
        map.setView(position, zoom);
    }, [position, zoom, map]);

    useEffect(() => {
        if (markers.length > 0) {
            const bounds = L.latLngBounds(markers.map((place: any) => [place.latitude, place.longitude]));
            map.fitBounds(bounds);
        }
    }, [markers, map]);

    return null;
};

const GestureHandlingSetter = () => {
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler("gestureHandling", GestureHandling);
    return null;
};

// @ts-ignore
const MapView = ({ position, zoom }) => {
    const mapRef: any = useRef(null);
    const markers = useSelector((state: RootState) => state.markers);
    console.log(markers);
    return (
        <MapContainer
            center={position}
            zoom={zoom}
            style={{ height: '100%', width: '100%', minHeight: '90dvh' }}
            ref={mapRef}
            scrollWheelZoom={false}
        >
            <GestureHandlingSetter />
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {   // @ts-ignore
                markers.map((place, index) => (
                    <Marker key={index} position={[place.latitude, place.longitude]} icon={customIcon}>
                        <Popup>
                            {place.name && <p><strong>{place.index}. {place.name}</strong><br /></p>}
                            {place.description && <p>{place.description}<br/></p>}
                            {place.long_description && <p>{place.long_description}<br/></p>}
                            {place.price && <p>Cost Status: <b>{place.price}</b><br/></p>}
                            {place.time_to_arrive && <p>Estimated time to arrive: <b>{place.time_to_arrive}</b><br/></p>}
                            {place.link && <a href={place.link} target="_blank" rel="noopener noreferrer">More info</a>}
                        </Popup>
                    </Marker>
                ))
            }
            <UpdateMapView position={position} zoom={zoom} markers={markers} />
        </MapContainer>
    );
};

export default MapView;
