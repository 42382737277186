import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import SelectField from './SelectField';
import SliderField from './SliderField';
import MultipleSelect from './MultipleSelect';
import ComboBox from "./ComboBox";
import Button from '@mui/material/Button';
import { City, Country } from 'country-state-city';
import { transliterate } from 'transliteration';
import { Action, Dispatch } from 'redux';
import { RootState } from '../store';
import {logout} from "../store/actions";

interface FormProps {
    onSubmit: (data: any) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setPosition: (position: [number, number]) => void;
    setZoom: (zoom: number) => void;
    setPlace: any;
}

const Form: React.FC<FormProps> = ({ onSubmit, loading, setLoading, setPosition, setZoom, setPlace }) => {
    const defaultOption = { label: "", value: "" };

    const allCountries = Country.getAllCountries();
    const countries = allCountries.map(({ name, isoCode }) => ({ label: name, value: isoCode }));
    const timeOfDay = [
        "Morning",
        "Afternoon",
        "Evening",
        "All day",
    ];
    const attractions = [
        "Museums",
        "Historical Sites",
        "Cultural Sites",
        "Galleries and Art",
        "Neighborhoods and Shopping",
        "Entertainment Venues",
        "Parks and Nature Spots",
        "Beaches and Waterfronts",
        "Sports and Recreation",
        "Architectural Wonders",
    ];
    const transport = [
        "Walking",
        "Car",
        "Bike",
        "Public Transport",
    ];
    const price = [
        "Free",
        "Paid",
        "Both",
    ];
    const physicalActivity = [
        "Low",
        "Medium",
        "High",
    ];

    const [country, setCountry] = useState(defaultOption);
    const [city, setCity] = useState(defaultOption);
    const [time, setTime] = useState("");
    const [attraction, setAttraction] = useState([]);
    const [transportation, setTransportation] = useState([]);
    const [priced, setPriced] = useState("");
    const [physical, setPhysical] = useState("");
    const [cities, setCities] = useState([]);
    const [range, setRange] = useState(3);
    const [submitLabel, setSubmitLabel] = useState("Submit");

    const formData = useSelector((state: RootState) => state.formData);

    const dispatch = useDispatch<Dispatch<Action>>();

    logout();

    useEffect(() => {
        if (formData) {
            const { country, city, timeOfDay, timeRange, attractionType, transport, price, physicalActivity } = formData;
            const selectedCountry: any = countries.find((c) => c.label === country) || defaultOption;
            const selectedCity: any = { label: city, value: city };
            const selectedTime: any = timeOfDay;
            const time = parseInt(timeRange);
            const selectedAttractions: any = attractionType ? attractionType.split(',').map((label: string) => (label)) : [];
            const selectedTransport: any = transport ? transport.split(',').map((label: string) => (label)) : [];
            const selectedPrice: any = price
            const selectedPhysical: any = physicalActivity

            setCountry(selectedCountry);
            setCity(selectedCity);
            setTime(selectedTime);
            setRange(time);
            setAttraction(selectedAttractions);
            setTransportation(selectedTransport);
            setPriced(selectedPrice);
            setPhysical(selectedPhysical);
        }
    }, [formData]);

    const handleCountryChange = (value: any) => {
        if (!value) return;
        let c: any = {};
        City.getCitiesOfCountry(value.value)?.map(({ name }) => {
            const key = transliterate(name).toLowerCase();
            c[key] = { label: transliterate(name), value: transliterate(name) };
        });
        c = Object.values(c);
        setCities(c);
        setCity(defaultOption);
        setCountry(value);
        const cntry: any = Country.getCountryByCode(value.value) || { latitude: 0, longitude: 0 }
        setPosition([cntry.latitude, cntry.longitude]);
        setZoom(6);
        setPlace(`${value}`);
    };

    const handleCityChange = (value: any) => {
        setPlace(`${value.label},${country.label}`);
        setCity(value);
    }

    const handleTimeChange = (value: any) => setTime(value);

    const handleRangeChange = (value: any) => setRange(+value);

    const handleAttractionChange = (value: any) => setAttraction(value);

    const handleTransportationChange = (value: any) => setTransportation(value);

    const handlePricedChange = (value: any) => setPriced(value);

    const handlePhysicalChange = (value: any) => setPhysical(value);

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSubmitLabel("Hang tight! Crafting your epic adventure...");

        const requestData = {
            country: country.label,
            city: city?.label,
            timeRange: range,
            attractionType: attraction.toString(),
            timeOfDay: time,
            transport: transportation.toString(),
            price: priced,
            physicalActivity: physical,
        };

        try {
            const response = await fetch('https://cv.marios.com.gr/tg.php', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log(data)
            dispatch({ type: 'SET_MARKERS', payload: data.list });
            onSubmit(data.list);
            const history = JSON.parse(localStorage.getItem('history') || '[]');
            const date = new Date();
            const dateFormatted = date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0];
            history.push({ date: dateFormatted, req: requestData, res: data.list });
            localStorage.setItem("history", JSON.stringify(history));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
            setSubmitLabel("Submit");
        }
    };

    return (
        <div className="space-y-6">
            <ComboBox
                id={"country"}
                label="Select Country"
                value={country}
                onChange={handleCountryChange}
                options={countries}
                showFlags={true}
            />
            <ComboBox
                id={"city"}
                label="Select City"
                value={city}
                onChange={handleCityChange}
                options={cities}
                showFlags={false}
            />
            <SelectField
                id={"time"}
                label="What time of day will you be visiting?"
                value={time}
                onChange={handleTimeChange}
                options={timeOfDay}
            />
            <SliderField
                id={"range"}
                label="How long should the tour be?"
                value={range}
                onChange={handleRangeChange}
                min={0}
                max={10}
                step={1}
                displayValue={range > 0 ? (range + " hour" + (range > 1 ? "s" : "")) : 'Less than an hour'}
            />
            <MultipleSelect
                id={"attraction"}
                label="Preferred type of attractions"
                value={attraction}
                onChange={handleAttractionChange}
                options={attractions}
            />
            <MultipleSelect
                id={"transportation"}
                label="Preferred mode of transportation"
                value={transportation}
                onChange={handleTransportationChange}
                options={transport}
            />
            <SelectField
                id={"priced"}
                label="Interest in free activities or ticketed attractions"
                value={priced}
                onChange={handlePricedChange}
                options={price}
            />
            <SelectField
                id={"physical"}
                label="Level of physical activity preferred"
                value={physical}
                onChange={handlePhysicalChange}
                options={physicalActivity}
            />
            {/* @ts-ignore */}
            <Button
                className="w-full"
                placeholder=""
                ariant="contained"
                color="success"
                onClick={handleFormSubmit}
                disabled={!country.value || (!city.value && cities.length > 0)}
            >
                { submitLabel }
            </Button>
        </div>
    );
};

export default Form;
