import React from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import {Container } from '@mui/material';
import Header from './components/Header';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import {useSelector} from "react-redux";
import {RootState} from "./store";

function App() {
    const themeMode = useSelector((state: RootState) => state.themeMode);
    return (
        <div className={"App " + themeMode}>
            <AnimatedBackground />
            <Header />
            <Container maxWidth="xl">
                <AppRoutes />
            </Container>
            <Footer />
        </div>
    );
}

export default App;
