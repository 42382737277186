export type ThemeMode = 'light' | 'dark';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_SITE = 'SET_SITE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_MARKERS = 'SET_MARKERS';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_USER = 'SET_USER';
export const SET_PUBLIC_GUIDES = 'SET_PUBLIC_GUIDES';
export const SAVE_USER_LOCALLY = 'SAVE_USER_LOCALLY';
export const LOGOUT = 'LOGOUT';
export const LOAD_HISTORY = 'LOAD_HISTORY';
export const SET_PLACE = 'SET_PLACE';

export interface User {
    email: string;
    name: string;
    id: string;
    authToken: string;
    role: string;
    tokens: number;
}

export interface State {
    openMenu: boolean;
    markers: any[];
    formData: any;
    user: User | null;
    publicGuides: any[];
    history: any[];
    place: string;
    themeMode: ThemeMode;
    site: any;
}

export interface Action {
    type: string;
    payload?: any;
}

export const initialState: State = {
    openMenu: false,
    markers: [],
    formData: null,
    user: null,
    publicGuides: [],
    history: [],
    place: '',
    themeMode: 'dark',
    site: { name: 'City Guide', description: '', url: '' },
};
