import React from 'react';
import { Container, Box, Typography, Button, Grid, Card, CardContent, Avatar } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend as ChartLegend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(ArcElement, ChartTooltip, ChartLegend);

// Sample data for charts
const data = [
    { name: 'Jan', tokens: 400 },
    { name: 'Feb', tokens: 300 },
    { name: 'Mar', tokens: 200 },
    { name: 'Apr', tokens: 278 },
    { name: 'May', tokens: 189 },
    { name: 'Jun', tokens: 239 },
    { name: 'Jul', tokens: 349 },
];

const doughnutData = {
    labels: ['Guides Created', 'Tokens Purchased', 'Profile Views'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
    ],
};

const Dashboard: React.FC = () => {
    return (
        <Container maxWidth="lg" className="pt-6">
            {/* Header/Navbar */}
            <Box className="flex justify-between items-center mb-6">
                <Typography variant="h4">Dashboard</Typography>
                <Box>
                    <Button variant="contained" className="mr-2">Notifications</Button>
                    <Button variant="contained">Profile</Button>
                </Box>
            </Box>

            {/* Welcome Message */}
            <Box className="mb-6 text-center">
                <Typography variant="h5">Welcome, [User Name]!</Typography>
            </Box>

            {/* Tokens Summary & Profile Overview */}
            <Grid container spacing={4} className="mb-6">
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent className="text-center">
                            <Typography variant="h6">Tokens: [Current Balance]</Typography>
                            <Button variant="contained" color="primary" className="mt-4">Buy More Tokens</Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent className="flex items-center">
                            <Avatar alt="[User Name]" src="/static/images/avatar/1.jpg" className="mr-4" />
                            <Box>
                                <Typography variant="h6">Profile</Typography>
                                <Typography variant="body1">Edit your profile information.</Typography>
                                <Button variant="contained" color="primary" className="mt-2">Edit Profile</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Statistics Section */}
            <Box className="mb-6">
                <Typography variant="h6" className="mb-4">Your Activity</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Tokens Over Time</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={data}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="tokens" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Activity Breakdown</Typography>
                                <Doughnut data={doughnutData} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Saved Guides */}
            <Box className="mb-6">
                <Typography variant="h6" className="mb-4">Saved Guides</Typography>
                <Grid container spacing={3}>
                    {[1, 2, 3].map((guide) => (
                        <Grid item xs={12} sm={6} md={4} key={guide}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Guide {guide}</Typography>
                                    <Typography variant="body2">Description of the guide.</Typography>
                                    <Button variant="contained" color="primary" className="mt-4">View Guide</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Footer */}
            <Box className="mt-6">
                <Typography variant="body2" align="center">© 2024 Your App Name. All rights reserved.</Typography>
            </Box>
        </Container>
    );
};

export default Dashboard;
