// src/store/reducer.ts
import { State, Action, initialState,
    TOGGLE_THEME,
    SET_SITE,
    TOGGLE_MENU,
    SET_MARKERS,
    SET_FORM_DATA,
    SET_USER,
    SET_PUBLIC_GUIDES,
    SAVE_USER_LOCALLY,
    LOGOUT,
    LOAD_HISTORY,
    SET_PLACE,
} from './types';

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case TOGGLE_MENU:
            return { ...state, openMenu: !state.openMenu };
        case SET_MARKERS:
            return { ...state, markers: action.payload };
        case SET_FORM_DATA:
            return { ...state, formData: action.payload };
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_PUBLIC_GUIDES:
            return { ...state, publicGuides: action.payload };
        case SAVE_USER_LOCALLY:
            return { ...state, user: action.payload };
        case LOGOUT:
            console.log('Logging out')
            return { ...state, user: null };
        case LOAD_HISTORY:
            return { ...state, history: action.payload };
        case SET_PLACE:
            return { ...state, place: action.payload };
        case TOGGLE_THEME:
            return {
                ...state,
                themeMode: state.themeMode === 'light' ? 'dark' : 'light',
            };
        case SET_SITE:
            return {
                ...state,
                site: state.site
            };
        default:
            return state;
    }
};

export default reducer;
