import React from 'react';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Typography from "@mui/material/Typography";

function valuetext(value: number) {
    return `${value} hours`;
}

type SliderFieldProps = {
    id: string;
    label: string;
    value: number;
    onChange: any;
    min: number;
    max: number;
    step: number;
    displayValue: string;
};

const SliderField: React.FC<SliderFieldProps> = ({ id, label, value, onChange, min, max, step, displayValue }) => (
    <FormControl fullWidth>
        <Typography gutterBottom>{label}
            <span className="text-sm font-bold mt-1 block">{displayValue}</span>
        </Typography>
        <Slider
            aria-label={label}
            defaultValue={value}
            value={value}
            getAriaValueText={valuetext}
            step={step}
            marks
            min={min}
            max={max}
            valueLabelDisplay="auto"
            onChange={(_, value) => onChange(value as number)}
            size="small"
            sx={{
                    color: 'rgba(0,0,0,0.87)'
                }}
        />
    </FormControl>
);

export default SliderField;
