import React, { useState } from 'react';
import Form from '../components/Form';
import MarkersList from '../components/MarkersList';
import MapView  from "../components/MapView";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import Typography from "@mui/material/Typography";

const constructGoogleMapsUrl = (markers: any[], place: string) => {
    if (markers.length === 0) return "";

    const baseUrl = "https://www.google.com/maps/dir/?api=1&travelmode=walking&dir_action=navigate";
    // const origin = `${markers[0].latitude},${markers[0].longitude}`;
    const origin = `${markers[0].name},${place}`;
    // const destination = `${markers[markers.length - 1].latitude},${markers[markers.length - 1].longitude}`;
    const destination = `${markers[markers.length - 1].name},${place}`;
    const waypoints = markers.slice(1, -1)
        // .map(marker => `${marker.latitude},${marker.longitude}`)
        .map(marker => `${marker.name},${place}`)
        .join('|');

    return waypoints
        ? `${baseUrl}&origin=${origin}&destination=${destination}&waypoints=${waypoints}`
        : `${baseUrl}&origin=${origin}&destination=${destination}`;
};

const CreateGuide: React.FC = () => {
    const [position, setPosition] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(true);
    const [showSidebar, setShowSidebar] = useState(true);
    const [place, setPlace] = useState<any>("");

    const markers = useSelector((state: RootState) => state.markers);

    const themeMode = useSelector((state: RootState) => state.themeMode);
    const bgSidebar = themeMode === 'dark' ? 'sidebar-dark-bg' : 'bg-gray-100';

    const googleMapsUrl = constructGoogleMapsUrl(markers, place);
    // const appleMapsUrl = constructAppleMapsUrl(markers);

    const onSubmit = (data: any) => {
        setShowSidebar(false);
    };

    const toggleMapList = () => {
        setShowMap(!showMap);
        setShowSidebar(false);
    }
    return (
        <>
            <Typography
                component="h3"
                variant="h3"
                align="center"
                gutterBottom
            >
                Create Your Custom City Guide
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" component="p">
                Customize your city adventure with tailored options and let our AI generate a personalized itinerary. Discover the best attractions, complete with details on prices and travel times, all displayed on an interactive map. Enjoy seamless navigation and make the most of your journey!
            </Typography>
            <div className="actions flex flex-row justify-end gap-4 mb-2 mt-6">
                <div>
                    {googleMapsUrl && (
                        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer"
                           className="flex gap-4 font-bold">
                            Open on <img src={`/google_maps.png`} alt="Google map link"/>
                        </a>
                    )}
                </div>
                {googleMapsUrl && (
                    <div title={!showMap ? "Show map" : "Show list"} className={!showMap ? "show-map" : "show-list"}
                         onClick={() => toggleMapList()}/>
                )}
            </div>
            <div className="flex flex-col md:flex-row gap-4">
                <div
                    className={`sidebar flex-none md:max-w-sm w-full ${showSidebar ? 'block' : 'hidden'} md:block`}>
                    <div className={bgSidebar + " p-6 max-w-4xl mx-auto rounded-lg h-full"}>
                        <Form
                            onSubmit={onSubmit}
                            loading={loading}
                            setLoading={setLoading}
                            setPosition={setPosition}
                            setZoom={setZoom}
                            setPlace={setPlace}
                        />
                    </div>
                </div>
                <div className="main-content flex-grow rounded-lg">
                    {showMap && (
                        <MapView position={position} zoom={zoom}/>
                    )}
                    {!showMap && (
                        <MarkersList markers={markers} googleLink={googleMapsUrl}/>
                    )}
                </div>
            </div>
            <button
                className="fixed bottom-[3px] right-[3px] bg-yellow-700 text-black text-sm font-semibold py-1 px-2 border border-gray-400 rounded md:hidden z-[9999]"
                onClick={() => setShowSidebar(!showSidebar)}
            >
                {showSidebar ? 'Hide' : 'Show'} Fields
            </button>
        </>
    );
};

export default CreateGuide;
