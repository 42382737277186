import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import PublicGuides from './pages/PublicGuides';
import UserDashboard from './pages/UserDashboard';
import NotFound from './pages/NotFound';
import PricingPage from "./pages/PricingPage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/public-guides" element={<PublicGuides />} />
            <Route path="/user/*" element={<UserDashboard />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
