"use client";

import React from 'react';
import jsPDF from 'jspdf';

type Marker = {
    name?: string;
    description?: string;
    long_description?: string;
    price?: string;
    link?: string;
    time_to_arrive?: string;
};

type MarkersListProps = {
    markers: Marker[];
    googleLink?: string;
};

const generateGoogleMapsLink = (placeName: string) => {
    const baseURL = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedPlaceName = encodeURIComponent(placeName);
    return `${baseURL}${encodedPlaceName}`;
}

const MarkersList: React.FC<MarkersListProps> = ({ markers, googleLink }) => {
    const generatePDF = () => {
        const doc = new jsPDF();
        const title = 'Your Itinerary';

        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 14;
        const textWidth = pageWidth - margin * 2;

        doc.setFontSize(18);
        // @ts-ignore
        doc.setFont(undefined, 'bold');
        doc.text(title , margin, 22);
        // @ts-ignore
        doc.setFont(undefined, 'normal');

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 255);
        const mapLinkWidth = doc.getStringUnitWidth('Show on Google Map') * 12 / doc.internal.scaleFactor;
        const mapLinkX = pageWidth - margin - mapLinkWidth;
        doc.textWithLink('Show on Google Map', mapLinkX, 22, { url: googleLink });
        doc.setTextColor(0, 0, 0);

        let y = 45;
        markers.forEach((marker, index) => {
            doc.setFontSize(14);
            // @ts-ignore
            doc.setFont(undefined, 'bold');
            doc.text(`${index + 1}. ${marker.name || 'Unnamed Marker'}`, margin, y);
            doc.setFontSize(12);
            // @ts-ignore
            doc.setFont(undefined, 'normal');
            if (marker.description) {
                const lines = doc.splitTextToSize(`${marker.description}`, textWidth);
                doc.text(lines, margin, y + 6);
                y += 12;
            }
            if (marker.long_description) {
                const lines = doc.splitTextToSize(`${marker.long_description}`, textWidth);
                doc.text(lines, margin, y + 6);
                y += 6 * lines.length + 8
            }
            if (marker.time_to_arrive) {
                doc.text(`Time to arrive: ${marker.time_to_arrive}`, margin, y);
                y += 8;
            }
            if (marker.price) {
                doc.text(`Cost Status: ${marker.price}`, margin, y);
                y += 8;
            }
            if (marker.link) {
                doc.setTextColor(0, 0, 255);
                doc.textWithLink('More Info', margin, y, { url: marker.link });
                y += 6;
            }
            if (marker.name) {
                doc.textWithLink('Google map', margin, y, { url: generateGoogleMapsLink(marker.name) });
                doc.setTextColor(0, 0, 0);
                y += 8;
            }
            y += 6;
            if (y > 270) {
                doc.addPage();
                y = 20;
            }
        });

        doc.save('markers-list.pdf');
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <div className="mb-5 flex justify-between">
                <span className="md:text-3xl text-2xl font-bold">
                    Your Itinerary
                </span>
                {markers.length > 0 && <button
                    onClick={generatePDF}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                    <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
                    </svg>
                    <span>PDF</span>
                </button>}
            </div>
            {markers.length === 0 ? (
                <p>Oops! No adventures to show right now! Did you forget to fill out the fields?</p>
            ) : (
                <>
                    <ul className="space-y-4">
                        {markers.map((marker, index) => (
                            <li key={index} className="p-4 border border-gray-200 rounded-lg">
                                {marker.name && (
                                    <p className="font-bold text-lg mb-2">{index + 1}. {marker.name}</p>
                                )}
                                {marker.description && (
                                    <p className="text-gray-900 mb-2">{marker.description}</p>
                                )}
                                {marker.long_description && (
                                    <p className="text-gray-700 mb-2">{marker.long_description}</p>
                                )}
                                {marker.time_to_arrive && (
                                    <p className="text-gray-600 mb-2">Estimated time to arrive:
                                        <span className="font-bold"> {marker.time_to_arrive}</span>
                                    </p>
                                )}
                                {marker.price && (
                                    <p className="text-gray-600 mb-2">Cost Status:
                                        <span className="font-bold"> {marker.price}</span>
                                    </p>
                                )}
                                {marker.link && (
                                    <a href={marker.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                        More info
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default MarkersList;
