import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserGuides from './UserGuides';
import CreateGuide from './CreateGuide';
import BuyTokens from './BuyTokens';
import UserProfile from './UserProfile';
import Dashboard from './Dashboard';
import '../styles/UserDashboard.css';

const UserDashboard = () => {
    return (
        <div className="dashboard-container">
            <Routes>
                <Route path="guides" element={<UserGuides />} />
                <Route path="create-guide" element={<CreateGuide />} />
                <Route path="buy-tokens" element={<BuyTokens />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="" element={<Dashboard />} />
            </Routes>
        </div>
    );
};

export default UserDashboard;
