import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, value: readonly string[], theme: Theme) {
    return {
        fontWeight:
            value.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

type MultiSelectProps = {
    id: string;
    label: string;
    value: any;
    onChange: any;
    options: any;
};

const MultiSelect: React.FC<MultiSelectProps> = ({
                                                     id, label, value, onChange, options
                                                 }) => {
    const theme = useTheme();
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id={`label-${id}`}>{label}</InputLabel>
                <Select
                    labelId={`label-${id}`}
                    id={`select-${id}`}
                    multiple
                    value={value}
                    label={label}
                    onChange={handleChange}
                    renderValue={(selected: any) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value: any) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((name: any) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, value, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultiSelect;
