import { Action } from './types';
import { Dispatch } from 'redux';
import { TOGGLE_THEME } from './types';

export const saveUserLocally = (user: any): Action => {
    localStorage.setItem('user', JSON.stringify(user));
    return { type: 'SAVE_USER_LOCALLY', payload: user };
};

export const logout = () => {
    return (dispatch: Dispatch) => {
        console.log('Logging out 1');
        localStorage.removeItem('user');
        dispatch({ type: 'LOGOUT' });
    };
};

export const loadHistory = (): Action => {
    const history = JSON.parse(localStorage.getItem('history') || '[]');
    return { type: 'LOAD_HISTORY', payload: history };
};

export const toggleTheme = () => ({
    type: TOGGLE_THEME,
});
