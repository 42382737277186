import React, { useState } from 'react';
import { Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { selectSiteName } from '../store/selectors';
import UserMenu from './UserMenu';

const MenuContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(17, 25, 40, 0.2)',
    borderRadius: '30px',
    margin: '10px',
    padding: theme.spacing(1),
}));

const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const siteName = useSelector(selectSiteName);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawer = (
        <div>
            <List>
                <ListItem component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemText primary={siteName} />
                </ListItem>
                <ListItem component={Link} to="/register-login" onClick={handleDrawerToggle}>
                    <ListItemText primary="Register/Login" />
                </ListItem>
                <ListItem component={Link} to="/contact" onClick={handleDrawerToggle}>
                    <ListItemText primary="Contact" />
                </ListItem>
                <ListItem component={Link} to="/about-us" onClick={handleDrawerToggle}>
                    <ListItemText primary="About Us" />
                </ListItem>
                <ListItem component={Link} to="/public-guides" onClick={handleDrawerToggle}>
                    <ListItemText primary="Public Guides" />
                </ListItem>
                <ListItem>
                    <ThemeToggle />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className="mb-8">
            <Toolbar className="justify-between mt-2">
                <IconButton
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    {siteName}
                </Typography>
                <MenuContainer className="desktop-menu" sx={{ display: { xs: 'none', md: 'flex' }, mx: 'auto' }}>
                    <Button className="normal-case" color="inherit" component={Link} to="/pricing">Pricing</Button>
                    <Button className="normal-case" color="inherit" component={Link} to="/contact">Contact</Button>
                    <Button className="normal-case" color="inherit" component={Link} to="/about-us">About Us</Button>
                    <Button className="normal-case" color="inherit" component={Link} to="/public-guides">Public Guides</Button>
                    <ThemeToggle />
                </MenuContainer>
                <div className="flex items-center">
                    <MenuContainer className="desktop-menu" sx={{ display: { xs: 'none', md: 'flex' }, mx: 'auto' }}>
                        <Button className="normal-case" color="inherit" component={Link} to="/login">Login</Button>
                        <Button className="normal-case border-button" color="inherit" component={Link} to="/register">Register</Button>
                    </MenuContainer>
                    <UserMenu />
                </div>
            </Toolbar>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle} sx={{ width: '70%' }}>
                {drawer}
            </Drawer>
        </div>
    );
};

export default Header;
