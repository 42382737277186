import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Grid, Card, CardContent, CardMedia } from '@mui/material';
import FlagIcon from '../components/FlagIcon';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

// Sample data
const guides = [
    { id: 1, city: 'Paris', country: 'France', iso: 'fr', savedDate: '2024-01-15', cityImage: 'https://picsum.photos/800/600?random=1' },
    { id: 2, city: 'Lyon', country: 'France', iso: 'fr', savedDate: '2024-02-20', cityImage: 'https://picsum.photos/800/600?random=2' },
    { id: 3, city: 'Milan', country: 'Italy', iso: 'it', savedDate: '2024-03-10', cityImage: 'https://picsum.photos/800/600?random=3' },
    { id: 4, city: 'Madrid', country: 'Spain', iso: 'es', savedDate: '2024-04-05', cityImage: 'https://picsum.photos/800/600?random=4' },
];

const UserGuides: React.FC = () => {
    const [filter, setFilter] = useState('');

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const filteredGuides = guides.filter(guide =>
        guide.city.toLowerCase().includes(filter.toLowerCase()) ||
        guide.country.toLowerCase().includes(filter.toLowerCase())
    );

    // Group guides by country
    const groupedGuides = filteredGuides.reduce((groups, guide) => {
        if (!groups[guide.country]) {
            groups[guide.country] = [];
        }
        groups[guide.country].push(guide);
        return groups;
    }, {} as { [key: string]: typeof guides });
    console.log(groupedGuides)

    return (
        <Container maxWidth="lg" className="pt-6">
            <Box className="text-center mb-6">
                <Typography variant="h4">Saved Guides</Typography>
            </Box>
            <Box mt={4} mb={6} display="flex" justifyContent="center" sx={{maxWidth: '350px', mx: 'auto'}}>
                <TextField
                    label="Filter by City or Country"
                    variant="standard"
                    value={filter}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {/* Guides List */}
            {Object.keys(groupedGuides).map(country => (
                <Box key={country} className="mb-6">
                    <div className="flex">
                        <FlagIcon isoCode={groupedGuides[country][0].iso} width={20} />
                        <Typography variant="h5" className="mb-4 ml-4">{country}</Typography>
                    </div>
                    <Grid container spacing={4}>
                        {groupedGuides[country].map(guide => (
                            <Grid item xs={12} sm={6} md={4} key={guide.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={guide.cityImage}
                                        alt={`${guide.city} image`}
                                    />
                                    <CardContent>
                                        <Box className="flex items-center mb-4">

                                            <Typography variant="h6">{guide.city}</Typography>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">Saved on: {guide.savedDate}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Container>
    );
};

export default UserGuides;
